/* Login Modal */

.modal-login-form{
    padding: 10px;

}
.modal-login-form-close{
    color: rgba(252,30,52,1);
    font-size: 23px;
    /* position: absolute; */
    top: 8px;
    right: 8px;
    left: 8px;
}
.modal-login-form-input{
    font-size: 25px;
    padding: 2px 20px;
    line-height: 1;
    border: 2px solid;
    border-radius: 30px;
    outline: none;
}