@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.align-right {
    display: flex;
    justify-content: flex-end;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #ffffff;
}
.table-striped-rows thead {
    background-color: #ffffff;
}

body {
    background-color: #fafafa;
    /* font-family: 'Poppins', sans-serif; */
}
/* 
h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
} */

.flow-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    text-align: end;
    width: 100%;
    padding: 10px;
}

.layoutflow {
    height: 40em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
}

.textStyle {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    color: gray;
}

.descriptionStyle {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    color: black;
}

.textStyleChild {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 450;
    line-height: 1;
    color: black;
}

.textStyleChilds {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 550;
    line-height: 1;
}

.headingStyle {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.5;
    color: black;
}

.headingStyle-sub {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: black;
}

.headingStyle2 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    color: black;
}

.textStyle2 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

.textStyle3 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

.textStyle-small {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.5;
}

/* Remove bullet points */
.sub-menu-href {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: black;
    list-style-type: none;
    cursor: pointer; /* Add cursor pointer for better UX */
}

.ti-control-record {
    font-size: 20px;
    color: #1890ff;
}

.textStyles-small {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1;
}

.custom-select .ant-select-selector {
    border-radius: 10px; /* Adjust the value as needed */
}

.textStyle-charts-header {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
}

.textStyles-smallx {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    line-height: 1.5;
}

/* ant design date picker font change */
.ant-picker-input > input {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.5;
}

.my-select-container .ant-select .ant-select-selector {
    border-radius:20px;
  }
  .borderedSelect {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    &:hover {
      border-color: #356796 !important;
      border-top-color: rgb(53, 103, 150);
      border-right-color: rgb(53, 103, 150);
      border-bottom-color: rgb(53, 103, 150);
      border-left-color: rgb(53, 103, 150);
    }
}

.customDropdown {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    width: 250px;
    height: 30px;
}

.customInput {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    width: 250px;
}



.secondary-button {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    border-color: black;
}

.primary-button-design {
    margin-left: 10px;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    border-color: #1890ff;
    background-color: white;
    color: #1890ff;
}    

.danger-button {
    margin-left: 10px;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    background-color: white;
    border-color: red;
}    

.card-new {
    width: 90px;
    height: 90px;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.icon-new {
    font-size: 20px;
    margin-bottom: 10px;
} 

.my-select-container .ant-select .ant-select-selector {
    border-radius:20px;
  }
  .borderedSelect {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    &:hover {
      border-color: #356796 !important;
      border-top-color: rgb(53, 103, 150);
      border-right-color: rgb(53, 103, 150);
      border-bottom-color: rgb(53, 103, 150);
      border-left-color: rgb(53, 103, 150);
    }
}

.customDropdown {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    width: 250px;
    height: 'auto';
}

.customInput {
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid darkgray;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    width: 250px;
}

.svg-container {
    position: relative;
    width: 73px; /* Width of the larger SVG */
    height: 73px; /* Height of the larger SVG */
  }
  
  .svg-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .svg-overlay {
    width: 50px; /* Width of the smaller SVG */
    height: 50px; /* Height of the smaller SVG */
  }
  
  /* Custom CSS for active step */
.ant-steps-item-active .ant-steps-item-icon {
    background-color: 'red'; /* Replace #yourColor with the desired color */
    border-color: 'red'; /* Replace #yourColor with the desired color */
}

.custom-info-window .gm-ui-hover-effect {
    display: none !important;
}

.gm-fullscreen-control {
  border-radius: 12px !important;
  overflow: hidden;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #BA183A !important;
}

.ant-tabs-nav {
  width: 100% !important;
}

.ant-tabs-ink-bar {
  background-color: #BA183A !important;
}

.custom-tabs .ant-tabs-tab {
  width: 50% !important;
  display: flex;
  justify-content: center;
}

.custom-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.custom-scroll::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}