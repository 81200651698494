.card-container{
    /* background: purple; */
    max-width: 250px;
}

.dashboard-card-value{
    display: inline;
}

.dashboard-card-header{
    font-weight: 500;
}

.large{
    font-size: 55px;
}

.small{
    font-size: 25px;
}

/* VehicleCard */

.card-vehicle-card{
    border-radius: 0 5px 5px 0;
    position: relative;
    margin-left: 30px;
    padding: 0;
}

.card-vehicle-header-container{
    position: absolute;
    padding: 5px 0;
    color: #fff;
    left: -24px;
    top: -1px;
    width: 24px;
    bottom: -1px;
    border-radius: 5px 0 0 10px;
    /* height: 100px; */
    text-shadow: -1px 1px #00000050;
    text-align: center;
    /* height: 100px; */
}

/* LocationContainer */

.card-vehicle-container-header{
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
}

/* VehicaleCell */

.card-vehicle-cell{
    font-size: 13px;
    font-weight: 500;
}

.delay{
    border-radius: 60px;
    background: linear-gradient(to right, rgba(252,30,52,1) 60%,rgba(255,255,255,0) 100%); 
    padding: 2px 6px;
    color: #fff;
    font-style: oblique;
    font-size: 11px;
}
.delay-first-set{
    border-radius: 60px;
    background: linear-gradient(to right, rgb(16 173 122) 60%,rgba(255,255,255,0) 100%);
    padding: 2px 6px;
    color: #fff;
    font-style: oblique;
    font-size: 11px;
}
.delay-second-set{
    border-radius: 60px;
    background: linear-gradient(to right, rgb(231 104 14 / 70%) 60%,rgba(255,255,255,0) 100%);
    padding: 2px 6px;
    color: #fff;
    font-style: oblique;
    font-size: 11px;
}
.delay-third-set{
    border-radius: 60px;
    background: linear-gradient(to right, rgba(231, 191, 14, 0.7) 60%,rgba(255,255,255,0) 100%);
    padding: 2px 6px;
    color: #fff;
    font-style: oblique;
    font-size: 11px;
}

.vehicle-cell-status{
    border-left: 3px solid;
    padding-left: 5px;
    border-radius: 3px;
    font-size: 10px;
}
.vehicle-cell-requse-num{
    font-size: 11px;
   
}

.vehicle-cell{
    display: inline-table;
    flex-direction: row;
    align-items: center;
}

.l-started{
    border-color: #60a0ab;    
}

.l-completed{
    border-color: #60ab62;
}

.departure{
    border-color: #ab60a2;
}

.arrival{
    border-color: #f14d4d;
}

