/* customStyles.css */

body-style {
    font-family: 'monospace';
    color: #333; /* Replace #333 with the desired font color */
  }
  
  ul, ol {
    list-style: none; /* Remove bullet points for both unordered and ordered lists */
  }
  
  li {
    margin: 0; /* Remove default margin for list items */
    padding: 0; /* Remove default padding for list items */
  }
  