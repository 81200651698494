
  .action-container{
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    background-color: #fafafa;
  }
  
  .card-bg{
      border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    background-color: #fafafa;
  }
  
  .main-dropdown{
    margin-top: 20px;
    margin-left: 10px;
  }
  
  .main-badge{
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 60px;
    font-size: larger;
  }
  
  .main-detailview{
    margin-top: 7px;
    margin-left: 10px;
  }
  
  .table-container{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .main-advanded-filter{
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .filter-container{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .page-title-box .page-title{
    font-size: 17px;
    color: rgb(44, 54, 82);
    font-weight: 500;
    line-height: 18px;
    margin: 0px;
  }
  
  .container-x{
    display: flex; /* You can use another display value depending on your layout needs */
    flex-wrap: wrap; /* Allow items to wrap if they overflow the container */
    justify-content: space-between;
    padding: 20px; 
  }