.custom-descriptions .ant-descriptions-view {
    background-color: white;
    border: none;
}

.custom-descriptions .ant-descriptions-item-label {
    background-color: white;
}

.custom-descriptions .ant-descriptions-item-content {
    background-color: white;
}